<template>
  <div style="display: inline-flex;flex-direction: column;">
    <v-menu
        v-model="menu"
        open-on-click
        transition="slide-x-transition"
        offset-x
        bottom
        left
    >
      <template v-slot:activator="{ on }">
        <v-tooltip bottom color="#000000c4">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click="menu = true"
                v-bind="attrs"
                v-on="on"
                class="mt-1"
                style=""
                min-height="0"
                min-width="0"
                height="35"
                width="35">
              <v-icon size="22">mdi-shape-polygon-plus</v-icon>
            </v-btn>
          </template>
          <span>Draw</span>
        </v-tooltip>
      </template>
      <v-list dense>
        <v-list-item @click="draw('polygon')">
          <v-list-item-title>
            <v-icon>mdi-vector-polygon</v-icon>
            Draw polygon (3)
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="draw('rectangle')">
          <v-list-item-title>
            <v-icon>mdi-rectangle-outline</v-icon>
            Draw rectangle (4)
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="draw('assist-rectangle')">
          <v-list-item-title>
            <v-icon>mdi-vector-rectangle</v-icon>
            Draw assist rectangle (5)
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DrawTool",
  data() {
    return {
      menu: false
    }
  },
  methods: {
    draw(mode) {
      this.$emit('changeMode', mode)
    }
  }
}
</script>

<style scoped>

</style>
