<template>
  <div>
    <v-form :disabled="loading" v-model="valid" ref="formData" class="fill-height">
      <v-layout class="fill-height column">
        <span style="font-size: 14px">Group:</span>
        <v-combobox
            dense
            solo-inverted
            v-model="group"
            :items="groups"
            label="Group"
            solo
        ></v-combobox>
        <v-layout class="fill-height pb-2" align-center justify-center>
          <file-upload
              style="border-radius: 6px; width: 100%; height: 150px; border: 1px dashed var(--v-secondary-base);"
              :style="{border: error ? '2px dashed var(--v-error-base)' : success ? '2px dashed var(--v-success-base)' : '2px dashed var(--v-secondary-base)'}"
              :maximum="1"
              ref="upload"
              v-model="file"
              :drop="true"
              class="pt-2"
          >
            <template>
              <v-layout column justify-center class="py-6 fill-height"
                        style="align-items: center; background-color: var(--v-primary-base)">
                <v-icon size="40" :color="error ? 'error' : success ? 'success' : 'secondary'" class="mb-2">
                  mdi-script-text-outline
                </v-icon>
                <span class="mt-1" style="font-size: 15px">(Click to select file or drop it here)</span>
              </v-layout>
            </template>
          </file-upload>
        </v-layout>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import VueUploadComponent from 'vue-upload-component'
import {getGroup} from "@/backend"

export default {
  name: "UploadField",
  components: {
    'file-upload': VueUploadComponent
  },
  data() {
    return {
      group: undefined,
      groups: [],
      name: undefined,
      loading: false,
      valid: false,
      validData: false,
      extensions: ['geojson'],
      error: false,
      success: false,
      createOptionDialog: false
    }
  },
  props: {
    currentFile: {}
  },
  computed: {
    file: {
      get() {
        return this.currentFile
      },
      set(val) {
        this.$emit('update:currentFile', val)
      }
    },
  },
  mounted() {
    this.getGroups()
  },
  watch: {
    file(val) {
      if (val) {
        for (let i = 0; i < val.length; i++) {
          let extension = val[i].name.split('.').pop()
          this.error = !this.extensions.some(val => val.toUpperCase() === extension.toUpperCase())
          this.success = this.extensions.some(val => val.toUpperCase() === extension.toUpperCase())
          if (this.success) {
            this.$parent.valid = false
            const file = val[0].file
            const reader = new FileReader()
            reader.onload = e => {
              val[i].geometry = JSON.parse(e.target.result)
            }
            reader.readAsText(file)
            let name = val[i].name.split("")
            for (let i = name.length - 1; i >= 0; i--) {
              if (name[i] === ".") {
                name.splice(i, name.length - i)
                break
              }
            }
            val[i].fileName = name.join("")
          }
          if (this.error) this.$parent.valid = true
        }
      } else {
        this.success = false
        this.error = false
        this.$parent.valid = true
      }
    },
    createOptionDialog(val) {
      if (!val) {
        this.file = undefined
        this.validData = false
        this.$refs.formData.resetValidation()
      }
    }
  },
  methods: {
    async getGroups() {
      try {
        this.loading = true
        const res = await getGroup()
        this.groups = res.data
      } catch (e) {

      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
/deep/
.edit-input .v-input__control {
  min-height: 0 !important;
  height: 28px !important;
}

/deep/
.file-uploads label {
  cursor: pointer !important;
}
</style>
