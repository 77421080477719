<template>
  <v-dialog
      style="z-index: 400"
      v-model="dialog"
      persistent
      width="750">
    <v-card>
      <v-card-text class="px-4 pb-4" style="background-color: var(--v-block-base)">
        <v-layout justify-center align-center style="height: 90px">
          <v-btn icon small @click="dialog = false" style="position: absolute; top: 10px; right: 10px">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span style="font-size: 24px; color: #fafafa">Upload Field (*.geojson)</span>
        </v-layout>
        <v-layout style="min-height: 150px" align-center>
          <UploadField :currentFile.sync="file"/>
        </v-layout>
      </v-card-text>
      <v-card-actions style="background-color: var(--v-block-base)">
        <v-spacer/>
        <v-btn width="100" color="secondary" @click="submit">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UploadField from "@/components/home/dashboard/UploadField";
export default {
  name: "DialogUploadField",
  components: {UploadField},
  data() {
    return {
      dialog: false,
      file: undefined
    }
  },
  methods: {
    submit() {
      if (!this.file || !!this.file[0].error) return
      for (let index = 0; index < this.file[0].geometry.features.length; index++) {
        this.$emit('saveField', {
          name: this.file[0].fileName + '_' + index,
          geometry: this.file[0].geometry.features[index].geometry,
          isSelectField: true
        })
      }
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>