<template>
  <v-dialog
      persistent
      v-model="saveForm"
      width="650">
    <v-card :loading="loading">
      <v-card-text class="px-4 pt-8 pb-4" style="background-color: var(--v-primary-base)">
        <v-btn icon small @click="saveForm = false" style="position: absolute; top: 10px; right: 10px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-layout justify-center>
          <span style="font-size: 16px; text-transform: uppercase; font-weight: bold">Create Field</span>
        </v-layout>
        <v-form onSubmit="return false;" v-model="valid" ref="form">
          <v-layout class="pt-5">
            <div style="flex: 1" class="pr-2">
              <span>Field Name:</span>
              <v-text-field
                  dense
                  autofocus
                  @focus="$event.target.select()"
                  label="Name"
                  v-model="name"
                  solo
                  solo-inverted
                  :rules="[rules.required]"
              ></v-text-field>
            </div>
            <div style="flex: 1" class="pl-2">
              <span style="font-size: 14px">Group:</span>
              <v-combobox
                  dense
                  label="Group"
                  solo-inverted
                  v-model="group"
                  :items="groups"
                  solo
              ></v-combobox>
            </div>
          </v-layout>
          <v-checkbox color="secondary" dense hide-details v-model="isDetect" label="Auto detect crop"
                      class="mb-3"></v-checkbox>
          <v-btn width="100%" height="45" class="mb-4" color="secondary" @click="addNewField">Add Season</v-btn>
          <v-layout style="max-height: 30vh; overflow-y: auto">
            <v-expansion-panels>
              <v-expansion-panel
                  v-for="(item,i) in seasons"
                  :key="i"
              >
                <v-expansion-panel-header>
                  <v-layout align-center>
                    {{ item.seasonYear }}
                    <v-spacer/>
                    <v-btn icon x-small color="red" @click.native.stop="seasons.splice(i, 1)" class="mr-2">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-layout>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout align-center class="pb-2">
                    <div style="flex: 1" class="pr-1">
                      <span style="font-size: 14px">Season year:</span>
                      <YearPicker
                          @input="item.sowingDate = item.seasonYear + '-01-01'"
                          v-model="item.seasonYear"
                          label="Season year"
                          solo-inverted
                          dense
                          hide-details
                      />
                    </div>
                    <div style="flex: 1" class="px-1">
                      <span style="font-size: 14px">Crop name:</span>
                      <v-combobox
                          hide-details
                          solo-inverted
                          dense
                          v-model="item.cropName"
                          :items="crops"
                          label="Crop name"
                      >
                      </v-combobox>
                    </div>
                    <div style="flex: 1" class="pl-1">
                      <span style="font-size: 14px">Maturity:</span>
                      <v-text-field
                          hide-details
                          solo-inverted
                          dense
                          v-model="item.maturity"
                          label="Maturity"
                      ></v-text-field>
                    </div>
                  </v-layout>
                  <v-layout align-center class="py-2">
                    <div style="flex: 1" class="pr-1">
                      <span style="font-size: 14px">Sowing date:</span>
                      <DatePicker
                          @input="item.harvestingDate = item.sowingDate + '-01-01'"
                          :min="item.seasonYear +'-01-01'"
                          v-model="item.sowingDate"
                          label=""
                          solo-inverted
                          dense
                          hide-details
                      />
                    </div>
                    <div style="flex: 1" class="px-1">
                      <span style="font-size: 14px">Harvesting date:</span>
                      <DatePicker
                          :min="item.sowingDate ? item.sowingDate : item.seasonYear +'-01-01'"
                          clearable
                          v-model="item.harvestingDate"
                          label=""
                          solo-inverted
                          dense
                          hide-details
                      />
                    </div>
                    <div style="flex: 1" class="pl-1">
                    </div>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout>
          <v-layout class="pt-5">
            <v-spacer/>
            <v-btn width="100" type="submit" color="secondary" @click="submit" dark>Submit</v-btn>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {getGroup} from "@/backend";
import sleep from "@/ultis/sleep";
import YearPicker from "@/components/YearPicker";
import DatePicker from "@/components/DatePicker";

export default {
  name: "SaveField",
  components: {DatePicker, YearPicker},
  data() {
    return {
      isDetect: false,
      crops: ["Paddy",
        "Sugarcane",
        "Maize",
        "Cashew",
        "Wheat"],
      seasons: [
        {
          seasonYear: new Date().getFullYear(),
          cropName: undefined,
          maturity: undefined,
          sowingDate: new Date().getFullYear() + '-01-01'
        },
        {
          seasonYear: new Date().getFullYear() - 1,
          cropName: undefined,
          maturity: undefined,
          sowingDate: (new Date().getFullYear() - 1) + '-01-01'
        },
        {
          seasonYear: new Date().getFullYear() - 2,
          cropName: undefined,
          maturity: undefined,
          sowingDate: (new Date().getFullYear() - 2) + '-01-01'
        }
      ],
      year: undefined,
      loading: false,
      currentSurvey: undefined,
      group: undefined,
      groups: [],
      valid: false,
      saveForm: false,
      name: undefined,
      featureCollection: undefined,
      rules: {
        required: value => !!value || 'Name is required',
      },
    }
  },
  props: {},
  watch: {
    saveForm(val) {
      if (!val) {
        this.seasons = [
          {
            seasonYear: new Date().getFullYear(),
            cropName: undefined,
            maturity: undefined,
            sowingDate: new Date().getFullYear() + '-01-01'
          },
          {
            seasonYear: new Date().getFullYear() - 1,
            cropName: undefined,
            maturity: undefined,
            sowingDate: (new Date().getFullYear() - 1) + '-01-01'
          },
          {
            seasonYear: new Date().getFullYear() - 2,
            cropName: undefined,
            maturity: undefined,
            sowingDate: (new Date().getFullYear() - 2) + '-01-01'
          }
        ]
        this.currentSurvey = undefined
        this.name = undefined
        this.group = undefined
        if (this.$refs.form) this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    addNewField() {
      this.seasons.unshift({
        seasonYear: new Date().getFullYear(),
        cropName: undefined,
        maturity: undefined,
        sowingDate: new Date().getFullYear() + '-01-01'
      })
    },
    async openSaveForm(featureCollection, survey) {
      this.currentSurvey = survey
      this.featureCollection = featureCollection
      await this.getGroups()
      this.saveForm = true
    },
    async getGroups() {
      try {
        this.loading = true
        const res = await getGroup()
        this.groups = res.data
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    async submit() {
      await sleep(0)
      this.$refs.form.validate()
      if (!this.valid) return
      this.$emit('saveField', {
        isDetect: this.isDetect,
        survey_record_id: this.currentSurvey ? this.currentSurvey.id : undefined,
        name: this.name,
        group: this.group,
        geometry: this.featureCollection.features[0].geometry,
        information: this.seasons.filter(val => !!val.cropName)
      })
      this.saveForm = false
    }
  }
}
</script>

<style scoped>

</style>
