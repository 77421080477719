import randomColor from "@/ultis/randomColor"
import bbox from "@turf/bbox"

export default class VectorLayer {
  constructor(obj) {
    this._isVector = true
    this._id = obj.id
    this._name = obj.name
    this._opacity = obj.opacity ? obj.opacity : 1
    this._color = obj.color ? obj.color : randomColor.getColor(Math.floor(Math.random() * 10))
    this._paint = obj.style ? obj.style : this.getDefaultPaint(obj.type)
    this._type = obj.type ? obj.type : 'line'
    this._geometry = obj.geometry
    this._visibility = obj.visibility ? obj.visibility : 'visible'
    this._bbox = obj.geometry ? bbox(obj.geometry) : [103.596, 1.1443, 104.4309, 1.4835]
    this._meta = obj.meta
  }

  getDefaultPaint (type) {
    switch (type) {
      case 'line':
        return {
          'line-color': randomColor.getColor(Math.floor(Math.random() * 10)),
          'line-width': 2,
          'line-opacity': this._opacity
        }
      case 'fill':
        return {
          'fill-color': randomColor.getColor(Math.floor(Math.random() * 10)),
          'fill-opacity': this._opacity,
          'fill-outline-color': randomColor.getColor(Math.floor(Math.random() * 10))
        }
    }
  }

  getMapboxLayer() {
    return {
      'id': this._id,
      'type': this._type,
      'geometry': this._geometry,
      'visibility': this._visibility,
      'bbox': this._bbox,
      'source': this._id,
      'color': this._color,
      'meta': this._meta,
      'layout': {
        'visibility': this._visibility
      },
      'paint': this._paint
    }
  }

  get isVector() {
    return this._isVector
  }

  get type() {
    return this._type
  }

  get id() {
    return this._id
  }

  get name() {
    return this._name
  }

  get opacity() {
    return this._opacity
  }

  set opacity (newValue) {
    this._opacity = newValue
  }

  get paint() {
    return this._paint
  }

  set paint (newValue) {
     this._paint = newValue
  }

  get geometry() {
    return this._geometry
  }

  get visibility() {
    return this._visibility
  }

  set visibility (newValue) {
    this._visibility = newValue
  }

  get bbox() {
    return this._bbox
  }

  set bbox (newValue) {
    this._bbox = newValue
  }
}
