<template>
  <div style="position: relative">
    <v-menu
        v-model="menu"
        open-on-click
        transition="slide-x-transition"
        offset-x
        bottom
        left
    >
      <template v-slot:activator="{ ds }">
        <v-tooltip left color="#000000c4">
          <template v-slot:activator="{ on, attrs }" v-on="ds">
            <v-btn
                :disabled="disabled"
                @click="menu = true"
                v-bind="attrs"
                v-on="on"
                class="mt-1"
                style=""
                min-height="0"
                min-width="0"
                height="35"
                width="35">
              <v-icon size="22">mdi-pencil-ruler</v-icon>
            </v-btn>
          </template>
          <span>Ruler</span>
        </v-tooltip>
      </template>
      <v-list dense>
        <v-list-item @click="activeTool('distance')">
          <v-list-item-title>
            <v-icon>mdi-ruler</v-icon>
            Measure Distance
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="activeTool('area')">
          <v-list-item-title>
            <v-icon>mdi-ruler-square-compass</v-icon>
            Measure Area
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-if="active" style="width: 300px; height: 200px; position: absolute; top: 0; right: 50px; border-radius: 8px">
      <v-layout class="fill-height" column justify-center>
        <div
            style="height: 45px; background-color: var(--v-primary-base); width: 100%; flex: none; border-top-right-radius: 8px; border-top-left-radius: 8px">
          <v-layout class="fill-height px-2" align-center>
            <h3 style="color: #fafafa">Measurement</h3>
            <v-spacer/>
            <v-btn x-small icon color="white" @click="closeMeasureTool">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-layout>
        </div>
        <v-layout class="fill-height pa-2"
                  style="background-color: #fafafa; border-bottom-right-radius: 8px; border-bottom-left-radius: 8px"
                  align-center
                  column>
          <div style="height: 60px; width: 100%; flex: none">
            <v-select
                @change="changeUnit"
                v-if="mode === 'distance'"
                :items="distanceUnits"
                v-model="unit"
                dense
                solo
                hide-details
                item-text="name"
                return-object
                label="unit">
            </v-select>
            <v-select
                @change="changeUnit"
                v-else
                :items="areaUnits"
                v-model="unit"
                dense
                solo
                hide-details
                item-text="name"
                return-object
                label="unit">
            </v-select>
          </div>
          <div style="width: 100%; height: 30px; min-height: 0; flex: none">
            <v-layout class="fill-height" align-center>
              <h3 style="color: #333333">Measure result</h3>
              <v-spacer/>
              <v-btn @click="reDraw" class="elevation-2" fab x-small>
                <v-icon size="20" v-if="mode === 'distance'">mdi-ruler</v-icon>
                <v-icon size="20" v-else>mdi-ruler-square-compass</v-icon>
              </v-btn>
            </v-layout>
          </div>
          <v-layout style="width: 100%" class="px-2" align-center justify-center>
            <h2 v-if="result" class="mt-2" style="color: var(--v-primary-base)">{{ result.toFixed(4) }}
              {{ unit.code }}</h2>
          </v-layout>
        </v-layout>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeasureTool",
  data() {
    return {
      menu: false,
      unit: {},
      result: undefined,
      originResult: undefined,
      mode: 'distance',
      distanceUnits: [{
        name: 'Kilometer',
        code: 'km',
        value: 1
      }, {
        name: 'Meter',
        code: 'm',
        value: 1000
      }],
      areaUnits: [{
        name: 'Square Kilometer',
        code: 'sqkm',
        value: 1
      }, {
        name: 'Square Meter',
        code: 'sqm',
        value: 1000000
      }, {
        name: 'Hecta',
        code: 'ha',
        value: 100
      }]
    }
  },
  props: {
    disabled: {type: Boolean, default: false},
    activeMeasureTool: {type: Boolean, default: false, required: false}
  },
  computed: {
    active: {
      get() {
        return this.activeMeasureTool
      },
      set(val) {
        this.$emit('update:activeMeasureTool', val)
      }
    }
  },
  methods: {
    reDraw() {
      this.resetResult()
      switch (this.mode) {
        case 'distance':
          this.$emit('activeMeasureTool', this.mode, 'drag-line')
          break
        case 'area':
          this.$emit('activeMeasureTool', this.mode, 'polygon')
          break
      }
    },
    activeTool(currentMode) {
      this.active = true
      this.resetResult()
      this.mode = currentMode
      switch (currentMode) {
        case 'distance':
          this.unit = {
            name: 'Kilometer',
            code: 'km',
            value: 1
          }
          this.$emit('activeMeasureTool', this.mode, 'drag-line')
          break
        case 'area':
          this.unit = {
            name: 'Square Kilometer',
            code: 'sqkm',
            value: 1
          }
          this.$emit('activeMeasureTool', this.mode, 'polygon')
          break
      }
    },
    setResult(result) {
      this.originResult = result
      this.result = this.originResult * this.unit.value
    },
    changeUnit() {
      if (this.originResult) this.result = this.originResult * this.unit.value
    },
    resetResult() {
      this.originResult = undefined
      this.result = undefined
    },
    closeMeasureTool() {
      this.resetResult()
      this.active = false
      this.$emit('resetDraw')
    }
  }
}
</script>

<style scoped>

</style>
