<template>
  <v-layout justify-center>
    <v-menu offset-y style="z-index: 900">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="btn-create-field"
            color="secondary"
            dark
            v-bind="attrs"
            v-on="on"
            width="90%"
            style="border-radius: 8px"
            height="50">
          Add field
          <v-icon>mdi-menu-up</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="() => $emit('openDrawTool')">
          <v-list-item-title>
            <v-icon small class="mr-2">mdi-shape-polygon-plus</v-icon>
            <span style="color: white"> Draw Field On Map</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="() => $emit('openAddForm', 'survey')">
          <v-list-item-title>
            <v-icon small class="mr-2">mdi-content-copy</v-icon>
            <span style="color: white"> Import From Survey</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="() => $emit('openAddForm', 'file')">
          <v-list-item-title>
            <v-icon small class="mr-2">mdi-upload-outline</v-icon>
            <span style="color: white"> Upload Field</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="openDetectTool">
          <v-list-item-title>
            <v-icon small class="mr-2">mdi-flash-auto</v-icon>
            <span style="color: white"> Auto Detection</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <DialogUploadField ref="uploadForm" @saveField="data => $emit('saveField', data)"/>
    <DialogListSurvey ref="surveyForm" @saveField="data => $emit('saveField', data)"/>
  </v-layout>
</template>

<script>
import DialogUploadField from "@/components/home/dashboard/FieldControl/DialogUploadField";
import DialogListSurvey from "@/components/home/dashboard/FieldControl/DialogListSurvey";
import {mapState} from "@/store/ults";
export default {
  name: "MenuAddField",
  components: {DialogListSurvey, DialogUploadField},
  computed: {
    ...mapState('map', ['isDetect']),
  },
  methods: {
    openDetectTool() {
      this.isDetect = true
      this.$emit('openAddForm', 'detect')
    }
  }
}
</script>

<style scoped>

</style>