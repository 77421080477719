<template>
  <div style="position: relative; height: 100%; width:100%; overflow: hidden;" ref="mapBound">
    <v-layout class="fill-height">
      <div v-show="currentSync" id="mapSync" style="border-right: 2px var(--v-primary-base) solid" class="flex pr-2"/>
      <div class="flex">
        <div id='wrapper' class='map'>
        </div>
        <div class="map-container" @mouseleave="deSelect" ref="mapContainer"/>
      </div>
      <div class="location-detail">
        <v-layout class="fill-height" style="padding: 2px 4px" align-center>
          <div style="flex: none; height: 100%; width: 50px; border-right: 1px solid #7c7c7c; padding-top: 1px">
            <v-icon size="14" color="#333333">mdi-magnify</v-icon>
            {{ zoomLevel }}
          </div>
          <v-layout class="fill-height pl-2" align-center v-html="currentPoint">
          </v-layout>
        </v-layout>
      </div>
    </v-layout>
    <div style="width: 250px; position: absolute; top: 15px; left: 10px; z-index: 2">
      <SearchLocation @zoomToCenter="zoomToCenter" @onSearch="onSearch"/>
    </div>
    <v-layout style="width: 33px; height: 200px; position: absolute; top: 10px; right: 10px" column>
      <v-btn
          v-if="isDrawing && !editing"
          style="position: absolute; top: 5px; right: 45px; z-index: 2; font-size: 12px; border-top-left-radius: 0; border-bottom-left-radius: 0"
          min-width="0"
          min-height="0"
          width="75"
          height="35"
          @click="cancel">
        cancel
      </v-btn>
      <v-btn
          v-if="isDrawing && !editing"
          style="position: absolute; top: 5px; right: 120px; z-index: 2; font-size: 12px; border-top-right-radius: 0; border-bottom-right-radius: 0"
          min-width="0"
          min-height="0"
          width="75"
          height="35"
          @click="saveGeometry">
        save
      </v-btn>
      <v-tooltip left color="#000000c4" v-if="layerControl">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="isColorPicker = !isColorPicker"
              v-bind="attrs"
              v-on="on"
              class="mt-1"
              style=""
              min-height="0"
              min-width="0"
              height="35"
              width="35">
            <v-icon size="22" :color="isColorPicker ? 'secondary' : 'white'">mdi-hand-pointing-up</v-icon>
          </v-btn>
        </template>
        <span>Color Picker</span>
      </v-tooltip>
      <v-tooltip left color="#000000c4">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="mt-1" @click="mapFullscreen" style="" min-height="0" min-width="0"
                 height="35" width="35">
            <v-icon size="22" v-if="!isMapFullscreen">mdi-fullscreen</v-icon>
            <v-icon size="22" v-else>mdi-fullscreen-exit</v-icon>
          </v-btn>
        </template>
        <span>Fullscreen</span>
      </v-tooltip>
      <v-tooltip left color="#000000c4" v-if="layerControl">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              class="mt-1"
              @click="changeStatusMap"
              style=""
              min-height="0"
              min-width="0"
              height="35"
              width="35">
            <v-icon size="22" :color="currentSync ? 'secondary' : ''">mdi-flip-horizontal</v-icon>
          </v-btn>
        </template>
        <span>Split view</span>
      </v-tooltip>
      <DrawTool v-if="isDrawing" ref="draw" @changeMode="changeMode"/>
      <MeasureTool
          ref="measureTool"
          :disabled="isDrawing"
          :activeMeasureTool.sync="isOpenMeasurementTool"
          @activeMeasureTool="activeMeasureTool"
          @resetDraw="resetDraw"/>
      <v-tooltip left color="#000000c4">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="mt-1" @click="changeVisibilityLabel"
                 :color="labelDisplay ? 'secondary' : ''" min-height="0" min-width="0"
                 height="35" width="35">
            <v-icon size="22">mdi-alphabetical-variant</v-icon>
          </v-btn>
        </template>
        <span>Label layer</span>
      </v-tooltip>
    </v-layout>
    <PopupProperties ref="properties"/>
    <!--    <DetectTool-->
    <!--        ref="detectTool"-->
    <!--        :zoom.sync="zoomLevel"-->
    <!--        @submitDetection="submitDetection"-->
    <!--        @addDraw="addDrawToMap"-->
    <!--        @saveField="saveGeometry"-->
    <!--        @deleteAll="resetDraw"-->
    <!--    />-->
  </div>
</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import sleep from "@/ultis/sleep"
import utils from "@/ultis/genUUID"
import VectorLayer from "@/models/layer/vector/vector"
import RasterLayer from "@/models/layer/raster/raster"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import {CircleMode, DirectMode, SimpleSelectMode} from "@/ultis/draw"
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import DrawLineString from "@mapbox/mapbox-gl-draw/src/modes/draw_line_string"
import mapStyle from "@/assets/MapStyle/mapstyle.json"
import DrawTool from "@/components/DrawTool"
import SearchLocation from "@/components/SearchLocation"
import bbox from "@turf/bbox"
import area from '@turf/area'
import booleanIntersects from '@turf/boolean-intersects'
import point from 'turf-point'
import MeasureTool from "@/components/Measurement"
import distance from "@turf/line-distance"
import colorFormat from "@/ultis/ColorFormat"
import {mapState} from "@/store/ults"
import {detectFarm, getCluster, getCurrentBbox, getImages} from "@/backend"
import {debounce} from "lodash"
import PopupProperties from "@/components/PopupProperties";

let syncMap
let draw
let popup
let marker
let markers = []
export default {
  name: "Map",
  components: {PopupProperties, MeasureTool, SearchLocation, DrawTool},
  data() {
    return {
      detectionId: undefined,
      labelDisplay: true,
      currentUid: undefined,
      currentPoint: undefined,
      firstLoad: true,
      isSmall: false,
      disable: false,
      isColorPicker: false,
      isMapFullscreen: false,
      editing: false,
      map: undefined,
      clusterId: undefined,
      layers: [],
      imageLayers: [],
      currentFieldEdit: undefined,
      imageSelected: undefined,
      currentGeometry: {},
      currentArea: 0,
      isOpenMeasurementTool: false,
      popupArea: false,
      mode: {},
    }
  },
  computed: {
    ...mapState("aoi", ["bbox"]),
    ...mapState("map", ["zoomLevel", "detectFields", 'isDetect']),
    loading: {
      get() {
        return this.isLoading
      },
      set(val) {
        this.$emit('update:isLoading', val)
      }
    },
    isDrawing: {
      get() {
        return this.isDraw
      },
      set(val) {
        this.$emit('update:isDraw', val)
      }
    },
    currentSync: {
      get() {
        return this.sync
      },
      set(val) {
        this.$emit('update:sync', val)
      }
    }
  },
  props: {
    isFirstLoad: {type: Boolean, default: true},
    isLoading: {type: Boolean, default: false},
    currentField: {},
    sync: {type: Boolean, default: false},
    layerControl: {type: Boolean, default: false},
    focusable: {type: Boolean, default: false},
    area: {type: String, default: '0'},
    editable: {type: Boolean, default: false},
    isDraw: {type: Boolean, default: false},
    type: {type: Object, default: () => {}}
  },
  watch: {
    type(val) {
      this.changeArea()
    }
  },
  mounted() {
    this.map = new window.mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: mapStyle,
      center: [105.8466249704361, 21.003883313511878],
      zoom: 1,
      maxZoom: 23,
      minZoom: 1,
      attributionControl: false,
      preserveDrawingBuffer: true
    })
    syncMap = new window.mapboxgl.Map({
      container: 'mapSync', // container id
      style: require('@/assets/MapStyle/mapstyle.json'),
      zoom: 1, // starting zoom,
      maxZoom: 23,
      minZoom: 1,
      attributionControl: false,
      preserveDrawingBuffer: true
    })
    draw = new MapboxDraw({
      styles: require('@/assets/MapStyle/custom-draw-style.json'),
      keybindings: true,
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        line_string: false,
        polygon: false,
        trash: false
      },
      modes: {
        ...MapboxDraw.modes,
        draw_assisted_rectangle: DrawRectangleAssisted,
        draw_rectangle: DrawRectangle,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode,
        draw_circle: CircleMode,
        draw_polygon: DrawPolygon,
        draw_line: DrawLineString
      },
    })
    this.map.on("load", async () => {
      if (this.isFirstLoad) {
        const res = await getCurrentBbox()
        if (res.data.length) this.submitZoom(res.data)
      }
      await this.reSize()
      this.firstLoad = false
      this.changeArea()
      this.zoomLevel = this.map.getZoom().toFixed(2)
    })
    this.map.on("draw.create", this.setMeasureResult)
    this.map.on("draw.update", this.setMeasureResult)
    this.map.on("draw.delete", this.setMeasureResult)
    this.map.on("mousemove", e => {
      this.getPixelValue(e)
    })
    this.map.on("move", () => {
      if (!this.disable) {
        const center = this.map.getCenter()
        const zoom = this.map.getZoom()
        const pitch = this.map.getPitch()
        const bearing = this.map.getBearing()

        this.disable = true
        syncMap.setCenter(center)
        syncMap.setZoom(zoom)
        syncMap.setPitch(pitch)
        syncMap.setBearing(bearing)
        this.disable = false
      }
    })
    this.map.on("zoomend", () => {
      this.zoomLevel = this.map.getZoom().toFixed(2)
    })
    this.map.on("moveend", () => {
      this.changeArea()
    })
    this.map.on('click', (e) => {
      if (this.isDetect) this.selectFieldDetected(e)
      if (!this.currentField && !this.editing && !this.isDrawing && !this.isDetect) {
        this.getField(e)
      }
    })
    syncMap.on("move", () => {
      if (!this.disable) {
        const center = syncMap.getCenter()
        const zoom = syncMap.getZoom()
        const pitch = syncMap.getPitch()
        const bearing = syncMap.getBearing()

        this.disable = true
        this.map.setCenter(center)
        this.map.setZoom(zoom)
        this.map.setPitch(pitch)
        this.map.setBearing(bearing)
        this.disable = false
      }
    })
    syncMap.on('style.load', () => {
      this.addLabelLayer()
      if (this.isFirstLoad) this.addExData()
      this.map.resize()
    })
    this.map.dragRotate.disable()
    this.map.touchZoomRotate.disableRotation()
    this.map.addControl(new window.mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric'
    }), 'bottom-right')
    this.map.addControl(draw, 'top-right')
    this.drawControl()
    addEventListener('fullscreenchange', (e) => {
      if (!document.fullscreenElement) this.isMapFullscreen = false
    })
  },
  methods: {
    addExData() {
      let geo1 = require("@/assets/spore_aoi.json")
      this.addEx(geo1, '#F7CA18', utils.getUUID(), 'line')
      let geo2 = require("@/assets/Boundary_Polygon_Merged.json")
      this.addEx(geo2, '#F7CA18', utils.getUUID(), 'line')
      let geo3 = require("@/assets/Tree_Point_Tree.json")
      this.addEx(geo3, '#F7CA18', 'tree_sg', 'circle')

      this.map.on('click', 'tree_sg', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['tree_sg']
        })
        this.$refs.properties.openDialog(features[0].properties)
        // const clusterId = features[0].properties.cluster_id

      })
      let hoverLayer = null;
      this.map.on('mousemove', 'tree_sg', (e) => {
        this.map.getCanvas().style.cursor = 'pointer'
      });
      this.map.on('mouseleave', 'tree_sg', () => {
        this.map.getCanvas().style.cursor = ''
      });
    },
    addEx(geometry, color, id, type) {
      syncMap.addSource(id, {
        'type': 'geojson',
        'data': geometry
      })
      this.map.addSource(id, {
        'type': 'geojson',
        'data': geometry
      })
      let style = {}
      if (type === 'fill') style = {
        'fill-color': 'transparent',
        'fill-opacity': 1,
        'fill-outline-color': 'red'
      }
      else if (type === 'line')
        style = {
        'line-color': color,
        'line-width': 3
      }
      else if (type === 'circle')
        style = {
        'circle-radius': 4,
        'circle-color': 'red'
      }
      let vector = new VectorLayer({
        'id': id,
        'type': type,
        'geometry': geometry,
        'color': color,
        'meta': {},
        'style': style
      })
      let layer = vector.getMapboxLayer()
      this.map.addLayer(layer)
      syncMap.addLayer(layer)
    },
    selectFieldDetected(e) {
      if (this.detectionId) {
        const features = this.map.queryRenderedFeatures(e.point, {layers: [this.detectionId]})
        if (features[0]) {
          let field = this.detectFields.find(field => field.properties.code === features[0].properties.code)
          if (JSON.stringify(draw.getAll().features[0].geometry.coordinates) !== JSON.stringify(field.geometry.coordinates)) this.addDrawToMap(field.geometry)
        }
      }
    },
    submitDetection() {
      const mapCanvas = this.map.getCanvas()
      const baseCanvas = document.createElement("canvas")
      baseCanvas.width = mapCanvas.width
      baseCanvas.height = mapCanvas.height
      const context = baseCanvas.getContext("2d")

      const mapImage = new window.Image()
      mapImage.src = mapCanvas.toDataURL()
      mapImage.onload = () => {
        context.drawImage(mapImage, 0, 0)
        baseCanvas.toBlob((result) => {
          let binary = this.blobToFile(result, 'test')
          const formData = new FormData()
          formData.append('file', binary)
          formData.append('zoom_level', this.zoomLevel)
          formData.append('left', this.map.getBounds()._sw.lng)
          formData.append('right', this.map.getBounds()._ne.lng)
          formData.append('top', this.map.getBounds()._ne.lat)
          formData.append('bottom', this.map.getBounds()._sw.lat)
          this.submitDetect(formData)
        })
      }
    },
    async submitDetect(data) {
      try {
        this.resetDraw()
        if (this.detectionId) this.removeLayer(this.detectionId)
        this.detectFields = []
        this.loading = true
        const res = await detectFarm(data)
        res.data.features.forEach(feature => {
          feature.properties = {code: utils.getUUID()}
        })
        this.detectFields = res.data.features
        // res.data.features.forEach(feature => {
        //   this.addPolygonToMap(feature, 'yellow', utils.getUUID(), {detectField: true})
        // })
        this.detectionId = utils.getUUID()
        this.addPolygonToMap(res.data, 'yellow', this.detectionId, {detectField: true}, 'fill')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return new File([theBlob], fileName, {
        type: theBlob.type,
      })
    },
    addLabelLayer() {
      this.map.addLayer(require('@/assets/MapStyle/label.json'))
      syncMap.addLayer(require('@/assets/MapStyle/label.json'))
    },
    changeVisibilityLabel() {
      this.labelDisplay = !this.labelDisplay
      this.map.setLayoutProperty('label', 'visibility', this.labelDisplay ? 'visible' : 'none')
      syncMap.setLayoutProperty('label', 'visibility', this.labelDisplay ? 'visible' : 'none')
    },
    getField(e) {
      this.layers.forEach(layer => {
        if (layer.geometry) {
          layer.geometry.features.forEach(feature => {
            if ( booleanIntersects(point([e.lngLat.lng, e.lngLat.lat]), feature.geometry)) {
              this.$emit('selectField', feature.id)
            }
          })
        }
      })
    },
    refreshMap() {
      this.isSmall = false
      this.changeArea()
    },
    changeArea: debounce(function (e) {
      if (this.isFirstLoad && !this.firstLoad && !this.currentField && !this.editing && !this.isDrawing) this.getDataOfBound()
    }, 800),
    async getDataOfBound() {
      let zoom = this.map.getZoom()
      let bound = this.map.getBounds()
      if (zoom >= 13 && !this.isDetect) {
        this.isSmall = false
        this.removeCluster()
        await this.getGeometryOfBound([bound._sw.lng, bound._sw.lat, bound._ne.lng, bound._ne.lat])
      } else if (this.isSmall === false && !this.isDetect) {
        this.removeAllLayer()
        this.isSmall = true
        await this.getFieldSmallZoom()
        this.currentUid = undefined
        this.$emit('resetMonth')
      }
    },
    async getGeometryOfBound(bbox) {
      try {
        this.loading = true
        const res = await getImages({bbox: bbox, index: this.type.code})
        if (this.currentUid !== res.data.uid) {
          this.removeAllLayer()
          let geojson = {
            "type": "FeatureCollection",
            "name": 'aoi_fields',
            "features": res.data.fields
          }
          this.addPolygonToMap(geojson, '#3592FD', 'aoi_fields')

          let tmpImages = JSON.parse(JSON.stringify(res.data.images))
          tmpImages.map(image => {
            image.cloud_tile_url = image.cloud_tile_url ? image.cloud_tile_url + '&uid=' + res.data.uid : undefined
            image.tile_url = image.tile_url + '&uid=' + res.data.uid
            image.bbox = res.data.bbox
          })
          this.addMultiImageToMap(tmpImages)
          if (res.data.images && res.data.images.length) this.$emit('displayTimeLine', res.data.images)
        }
        this.currentUid = res.data.uid
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getFieldSmallZoom() {
      try {
        this.loading = true
        const res = await getCluster()
        this.displayCluster(res.data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    closeColorPicker() {
      this.isColorPicker = false
    },
    getPixelValue(e) {
      this.currentPoint = e.lngLat['lng'].toFixed(4) + ',&nbsp;&nbsp;' + e.lngLat['lat'].toFixed(4)
      this.map.getCanvas().style.cursor = ''
      if (popup) popup.remove()
      if (!this.isColorPicker) return
      if (!this.getWithinLayer([e.lngLat['lng'], e.lngLat['lat']])) return
      this.map.getCanvas().style.cursor = 'pointer'
      const {point} = e
      const {x, y} = point
      const canvas = this.map.getCanvas();
      const gl = canvas.getContext("webgl") || canvas.getContext("webgl2")
      if (gl) {
        const data = new Uint8Array(4)
        const canvasX = x - canvas.offsetLeft
        const canvasY = canvas.height - y - canvas.offsetTop
        gl.readPixels(
            canvasX,
            canvasY,
            1,
            1,
            gl.RGBA,
            gl.UNSIGNED_BYTE,
            data
        )
        const [r, g, b, a] = data;
        const color = `rgba(${r}, ${g}, ${b}, ${a})`
        let pointData = this.type.data.find(val => val.color === colorFormat.covertToHex(color).toUpperCase())
        if (pointData) {
          popup = new mapboxgl.Popup({closeOnClick: false})
              .setLngLat([e.lngLat['lng'], e.lngLat['lat']])
              .setHTML(`<div style="width: 90px; text-align: center;">
                        <div style="float: left; width: 20px; height: 20px; background-color: ${colorFormat.covertToHex(color).toUpperCase()}"></div>
                        <h4 style="float: left; color: black; margin-left: 10px">${pointData.value}</h4>
                        </div>`)
              .addTo(this.map)
        }
      }
    },
    getWithinLayer(currentPoint) {
      return booleanIntersects(point(currentPoint), this.currentField.geometry)
    },
    onSearch(address) {
      if (!address) return
      let bounds = JSON.parse(address.bbox)
      this.submitZoom([
        bounds.coordinates[0][0][0],
        bounds.coordinates[0][0][1],
        bounds.coordinates[0][3][0],
        bounds.coordinates[0][3][1]
      ])
    },
    zoomToCenter(point) {
      if (point) {
        try {
          let pt = point.replaceAll(' ', '').split(',')
          this.map.flyTo({
            center: [+pt[0], +pt[1]],
            zoom: 10
          })
          syncMap.flyTo({
            center: [+pt[0], +pt[1]],
            zoom: 10
          })
        } catch (e) {
        }
      }
    },
    changeMode(mode) {
      if (!this.isDrawing) return
      switch (mode) {
        case 'polygon':
          if (draw.getMode() !== 'draw_polygon') draw.changeMode('draw_polygon')
          break
        case 'assist-rectangle':
          if (draw.getMode() !== 'draw_assisted_rectangle') draw.changeMode('draw_assisted_rectangle')
          break
        case 'rectangle':
          if (draw.getMode() !== 'draw_rectangle') draw.changeMode('draw_rectangle')
          break
        case 'delete':
          if (draw.getSelected()) {
            draw.trash()
          }
          break
        default:
          break
      }
      for (let drawStyle of draw.options.styles) {
        this.map.moveLayer(drawStyle.id)
      }
    },
    drawControl() {
      this.onKeyUp = function (event) {
        switch (event.key) {
          case '3':
          case 'q':
            if (draw.getMode() !== 'draw_polygon' && this.isDrawing) draw.changeMode('draw_polygon')
            break
          case '4':
          case 'w':
            if (draw.getMode() !== 'draw_rectangle' && this.isDrawing) draw.changeMode('draw_rectangle')
            break
          case '5':
          case 'e':
            if (draw.getMode() !== 'draw_assisted_rectangle' && this.isDrawing) draw.changeMode('draw_assisted_rectangle')
            break
          case 'Delete':
          case 'Backspace':
            if (draw.getSelected()) {
              draw.trash()
            }
            break
          default:
            break
        }
      }.bind(this)

      window.addEventListener('keyup', this.onKeyUp)
    },
    destroyDrawControlShortcuts() {
      if (this.onKeyUp) window.removeEventListener('keyup', this.onKeyUp)
    },
    resetDraw() {
      if (this.detectionId) {
        this.removeLayer(this.detectionId)
        this.detectionId = undefined
      }
      draw.deleteAll()
      draw.changeMode('simple_select')
    },
    deSelect() {
      draw.changeMode('simple_select')
    },
    activeMeasureTool(mode, drawMode) {
      this.resetDraw()
      this.mode = mode
      switch (drawMode) {
        case "polygon":
          draw.changeMode("draw_polygon")
          break
        case "drag-line":
          draw.changeMode("draw_line_string")
          break
        default:
          break
      }
      for (let drawStyle of draw.options.styles) {
        this.map.moveLayer(drawStyle.id)
      }
    },
    setMeasureResult() {
      switch (this.mode) {
        case 'distance':
          this.$refs.measureTool.setResult(distance(draw.getAll()))
          break
        case 'area':
          this.$refs.measureTool.setResult(area(draw.getAll()) / 1000000)
          break
      }
    },
    async changeStatusMap() {
      this.currentSync = !this.currentSync
      let mapCanvas = document.getElementsByClassName('mapboxgl-canvas')
      for (let i = 0; i < mapCanvas.length; i++) {
        mapCanvas[i].style.height = '100%'
        mapCanvas[i].style.width = '100%'
      }
      await sleep(0)
      this.map.resize()
      syncMap.resize()
    },
    async reSize() {
      let mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0]
      if (mapCanvas) {
        mapCanvas.style.height = '100%'
        mapCanvas.style.width = '100%'
        await sleep(100)
        this.map.resize()
      }
    },
    mapFullscreen() {
      this.isMapFullscreen = !this.isMapFullscreen
      if (this.isMapFullscreen) this.$refs.mapBound.parentElement.requestFullscreen()
      else document.exitFullscreen()
    },
    displayCluster(data) {
      this.removeAllLayer()
      this.removeCluster()
      this.clusterId = utils.getUUID()
      this.layers = []
      this.map.addSource(this.clusterId, {
        type: 'geojson',
        data: data,
        cluster: true,
        clusterMaxZoom: 18,
        clusterRadius: 18
      })
      // Add layer to map
      this.map.addLayer({
        id: 'clusters' + this.clusterId,
        type: 'circle',
        source: this.clusterId,
        filter: ['has', 'point_count'],
        paint: {
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fffdfd',
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#79008c',
            100,
            '#0c4f44',
            500,
            '#ff6b00',
            1000,
            '#0049ff',
            5000,
            '#ff0000'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      })
      this.map.addLayer({
        id: 'cluster-count' + this.clusterId,
        type: 'symbol',
        source: this.clusterId,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 12,
          'text-allow-overlap': true,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'text-ignore-placement': true
        },
        paint: {
          "text-color": "#ffffff"
        }
      })
      this.map.addLayer({
        id: 'unclustered-point' + this.clusterId,
        type: 'circle',
        source: this.clusterId,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#da1122',
          'circle-radius': 7,
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fffdfd'
        }
      })
      // on Click layer
      this.map.on('click', 'clusters' + this.clusterId, (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters' + this.clusterId]
        })
        const clusterId = features[0].properties.cluster_id
        this.map.getSource(this.clusterId).getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return
              this.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              })
            }
        )
      })
      this.map.on('click', 'unclustered-point' + this.clusterId, async (e) => {
        this.$emit('selectField', e.features[0].properties.id)
        if (document.fullscreenElement) await document.exitFullscreen()
      })
      this.layers.push('clusters' + this.clusterId, 'cluster-count' + this.clusterId, 'unclustered-point' + this.clusterId)
    },
    addDrawToMap(geometry) {
      draw.deleteAll()
      draw.set({
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "properties": {},
            "geometry": geometry
          }
        ]
      })
      // this.map.fitBounds(bbox(geometry), {
      //   'duration': 0,
      //   'padding': 50
      // })
      this.moveDrawLayerToTop()
    },
    editLayer(field) {
      this.layers.forEach(layer => {
        this.map.setLayoutProperty(layer.id, "visibility", "none")
      })
      this.imageLayers.forEach(layer => {
        this.map.setLayoutProperty(layer.id, "visibility", "none")
      })
      this.currentFieldEdit = field
      // this.map.setLayoutProperty(field.layerId, "visibility", "none")
      let ids = draw.set(field.featureCollection)
      draw.changeMode('direct_select', {
        featureId: ids[0],
      })
      if (this.layers.length > 0) {
        for (let drawStyle of draw.options.styles) {
          this.map.moveLayer(drawStyle.id)
        }
      }
      this.submitZoom(bbox(field.featureCollection))
      this.editing = true
      this.isDrawing = true
      this.reSize()
    },
    cancelEdit() {
      if (this.map.getStyle().layers.some(val => val.id === this.currentFieldEdit.layerId)) this.map.setLayoutProperty(this.currentFieldEdit.layerId, "visibility", "visible")
      this.editing = false
      this.isDrawing = false
      this.currentFieldEdit = undefined
      draw.deleteAll()
      this.reSize()
    },
    updateField(fieldInfo) {
      let features = draw.getAll().features
      let index = features.findIndex(val => val.geometry.coordinates[0].length < 2)
      if (index >= 0) features.splice(index, 1)
      if (features.length !== 1) return (this.$store.commit('message/SHOW_ERROR', 'Invalid Field'))
      this.editing = false
      this.isDrawing = false
      if (JSON.stringify(this.currentFieldEdit.geometry.coordinates) !== JSON.stringify(features[0].geometry.coordinates)) fieldInfo.updated_geometry = features[0].geometry
      this.$emit('updateField', {
        id: this.currentFieldEdit.id,
        data: fieldInfo
      })
      this.currentFieldEdit = undefined
      draw.deleteAll()
      this.reSize()
    },
    cancel() {
      draw.deleteAll()
      this.isDrawing = false
      draw.changeMode('simple_select')
      this.reSize()
    },
    saveGeometry() {
      let currentGeometry = draw.getAll()
      if (currentGeometry.features.length !== 1) return (this.$store.commit('message/SHOW_ERROR', 'Invalid Field'))
      this.$emit('saveField', draw.getAll())
      this.editing = false
      this.reSize()
    },
    displayVector(geometry, type, color, id) {
      this.currentGeometry = geometry
      switch (type) {
        case 'marker':
          this.addMarkerToMap(geometry, color)
          break
        case 'polygon':
          this.addPolygonToMap(geometry, color, id)
          break
      }
    },
    addMarkerToMap(geometry, color) {
      let currentMarker = new mapboxgl.Marker({color: color})
          .setLngLat(geometry.coordinates)
          .addTo(this.map)
      markers.push(currentMarker)
      this.submitFlyTo(geometry.coordinates)
    },
    addZoningData(vectorData) {
      this.map.addSource(vectorData.id, {
        'type': 'geojson',
        'data': vectorData.geometry
      })
      let vector = new VectorLayer(vectorData)
      let layer = vector.getMapboxLayer()
      this.map.addLayer(layer)
      this.layers.push(layer)
    },
    addPolygonToMap(geometry, color, id, meta = {}, type, paint = undefined, isMap = true, isSyncMap = true) {
      syncMap.addSource(id, {
        'type': 'geojson',
        'data': geometry
      })
      this.map.addSource(id, {
        'type': 'geojson',
        'data': geometry
      })
      let style = {}
      if(paint) style = paint
      else style = type === 'fill' ? {
        'fill-color': color,
        'fill-opacity': 1,
        'fill-outline-color': 'blue'
      } : {
        'line-color': color,
        'line-width': 3
      }
      let vector = new VectorLayer({
        'id': id,
        'type': type,
        'geometry': geometry,
        'color': color,
        'meta': meta,
        'style': style
      })
      let layer = vector.getMapboxLayer()
      if(isMap) this.map.addLayer(layer)
      if(isSyncMap) syncMap.addLayer(layer)
      this.layers.push(layer)
    },
    addMultiImageToMap(layers) {
      this.removeAllImage()
      layers.forEach((layer, index) => {
        this.addImageToMap(layer.tile_url, layer.id, !index ? 'visible' : 'none', layer.bbox ? layer.bbox : bbox(layer.geometry))
        if (layer.cloud_tile_url) this.addImageToMap(layer.cloud_tile_url, 'cloud_' + layer.id, !index ? 'visible' : 'none', layer.bbox ? layer.bbox : bbox(layer.geometry))
      })
    },
    addImageToMap(tileUrl, id, visibility, bbox, left = true, right = true) {
      // let bbox = this.layers[0].bbox
      let raster = new RasterLayer({
        id: id,
        name: name,
        tileUrl: tileUrl,
        bbox: bbox,
        visibility: visibility
      })
      if (right) this.map.addLayer(raster.getMapboxLayer())
      if (left) syncMap.addLayer(raster.getMapboxLayer())
      this.imageLayers.push(raster.getMapboxLayer())
      if (visibility === 'visible') {
        this.$emit('getWeatherData', raster.getMapboxLayer().name)
        this.imageSelected = raster.getMapboxLayer().id
      }
    },
    removeAllImage() {
      this.imageLayers.forEach(layer => {
        this.removeLayer(layer.id)
      })
      this.imageSelected = undefined
      this.imageLayers = []
    },
    removeCluster() {
      if (this.clusterId) {
        this.map.removeLayer('clusters' + this.clusterId)
        this.map.removeLayer('cluster-count' + this.clusterId)
        this.map.removeLayer('unclustered-point' + this.clusterId)
        this.map.removeSource(this.clusterId)
        this.clusterId = undefined
      }
    },
    removeAllLayer() {
      this.layers.forEach(layer => {
        if (this.map.getStyle().layers.some(val => val.id === layer.id)) {
          this.removeLayer(layer.id)
        }
      })
      this.layers = []
      this.detectionId = undefined
    },
    removeLayer(layerId) {
      if (this.map.getStyle().layers.some(val => val.id === layerId)) {
        this.map.removeLayer(layerId)
        this.map.removeSource(layerId)
      }
      if (syncMap.getStyle().layers.some(val => val.id === layerId)) {
        syncMap.removeLayer(layerId)
        syncMap.removeSource(layerId)
      }
    },
    changeVisibility(layerId) {
      this.imageLayers.forEach(layer => {
        this.map.setLayoutProperty(layer.id, 'visibility', 'none')
      })
      this.map.setLayoutProperty(layerId, 'visibility', 'visible')
      this.$emit('getWeatherData', this.imageLayers.find(layer => layerId === layer.id).name)
      if (this.map.getStyle().layers.some(val => val.id === 'cloud_' + layerId)) this.map.setLayoutProperty('cloud_' + layerId, 'visibility', 'visible')
    },
    changeVisibilityCompare(layerId) {
      this.imageLayers.forEach(layer => {
        syncMap.setLayoutProperty(layer.id, 'visibility', 'none')
      })
      syncMap.setLayoutProperty(layerId, 'visibility', 'visible')
      this.$emit('getWeatherData', this.imageLayers.find(layer => layerId === layer.id).name)
      if (syncMap.getStyle().layers.some(val => val.id === 'cloud_' + layerId)) syncMap.setLayoutProperty('cloud_' + layerId, 'visibility', 'visible')
    },
    zoomToLayer(layerId) {
      let layer = this.layers.find(val => val.id === layerId)
      if (layer) this.submitZoom(layer.bbox)
    },
    submitFlyTo(point) {
      this.map.easeTo({
        center: point,
        zoom: 15.5
      })
    },
    async submitZoom(bbox) {
      await this.reSize()
      await sleep(0)
      this.map.fitBounds(bbox, {
        'duration': 0,
        'padding': 20
      })
    },
    moveDrawLayerToTop() {
      for (let drawStyle of draw.options.styles) {
        this.map.moveLayer(drawStyle.id);
      }
    },
  },
  destroyed() {
    if (this.map) {
      this.map.remove()
      draw = undefined
      this.map = undefined
      markers = []
      this.imageLayers = []
      this.layers = []
    }
  }
}
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
}

.location-detail {
  position: absolute;
  bottom: 10px;
  right: 80px;
  background-color: #FFFFFFC0;
  border-radius: 4px;
  width: 160px;
  height: 20px;
  color: #333333;
  font-size: 10px
}

</style>
