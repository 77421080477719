<template>
  <v-dialog
      style="z-index: 400"
      v-model="dialog"
      persistent
      width="750">
    <v-card>
      <v-card-text class="px-4 pb-4" style="background-color: var(--v-block-base)">
        <v-layout justify-center align-center style="height: 90px">
          <v-btn icon small @click="dialog = false" style="position: absolute; top: 10px; right: 10px">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span style="font-size: 24px; color: #fafafa">Import from Survey</span>
        </v-layout>
        <v-layout style="min-height: 150px; max-height: 450px;" column>
          <v-layout class="flex-wrap py-2">
            <v-spacer/>
            <div style="width: 250px">
              <v-text-field
                  v-model="recordFilter.search"
                  @input="debounceInput"
                  solo-inverted
                  hide-details
                  dense
                  label="Search"
                  append-icon="mdi-magnify"/>
            </div>
          </v-layout>
          <v-data-table
              v-model="selectedFarm"
              single-select
              @click:row="rowClick"
              height="280"
              fixed-header
              :headers="headers"
              :items="reports"
              :search="recordFilter.search"
              :loading="loading"
              :items-per-page="10"
              class="row-pointer order-table d-flex flex-column"
              hide-default-footer
              :server-items-length.sync="totalItem"
              loading-text="Loading... Please wait"
              :options.sync="options"
              :page.sync="page"
          >
            <template v-slot:[`item.preview`]="{ item }">
              <div style="width: 60px; height: 60px">
                <MapPreview :geojson="item.featureCollection" :id="'farm_' + item.id"/>
              </div>
            </template>
          </v-data-table>
          <div class="text-center" style="flex: none; height: auto">
            <v-pagination :total-visible="5" v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-layout>
      </v-card-text>
      <v-card-actions style="background-color: var(--v-block-base)">
        <v-spacer/>
        <v-btn width="100" color="secondary" @click="submit">Submit</v-btn>
      </v-card-actions>
      <SaveField ref="saveField" @saveField="(data) => $emit('saveField', data)"/>
    </v-card>
  </v-dialog>
</template>

<script>
import {debounce} from "lodash"
import MapPreview from "@/components/MapPreview";
import {getRecords} from "@/backend";
import SaveField from "@/components/home/dashboard/FieldControl/SaveField";
export default {
  name: "DialogListSurvey",
  components: {SaveField, MapPreview},
  data() {
    return {
      firstLoad: true,
      dialog: false,
      selectedFarm: undefined,
      step: 1,
      type: undefined,
      loading: false,
      headers: [
        {text: "Preview", value: "preview", sortable: false, align: "start"},
        {text: "Survey name", value: "survey", sortable: true, align: "start"},
      ],
      totalItem: 100,
      pageCount: 0,
      recordFilter: {
        search: undefined,
      },
      reports: [],
      page: 1,
      options: {},
    }
  },
  watch: {
    options: {
      handler() {
        this.getRecord()
      },
      deep: true
    }
  },
  methods: {
    resetData() {
      this.reports = []
      this.recordFilter.search = ''
      this.page = 1
      this.selectedFarm = undefined
      this.file = undefined
    },
    rowClick: function (item, row) {
      row.select(true)
      this.selectedFarm = [item]
    },
    getParamRecord() {
      let sort
      if (this.options.sortBy.length && this.options.sortBy.length) {
        if (this.options.sortDesc[0]) sort = '-' + this.options.sortBy[0]
        else sort = this.options.sortBy[0]
      }
      let param = JSON.parse(JSON.stringify(this.recordFilter))
      param['per_page'] = 5
      param['page'] = this.options.page
      param['sort'] = sort
      return param
    },
    debounceInput: debounce(function (e) {
      this.getRecord()
    }, 500),
    async getRecord() {
      try {
        this.selectedFarm = undefined
        this.reports = []
        this.loading = true
        const resRecord = await getRecords(this.getParamRecord())
        this.reports = resRecord.data.data
        this.reports.forEach(field => {
          field.featureCollection = {
            "type": "FeatureCollection",
            "name": 'farm_' + field.id,
            "features": [
              {
                "properties": {"farm_id": field.id},
                "type": "Feature",
                "geometry": JSON.parse(field.geometry)
              }]
          }
        })
        this.totalItem = resRecord.data.total
        this.pageCount = resRecord.data.last_page
      } catch (e) {

      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    submit() {
      this.$refs.saveField.openSaveForm(this.selectedFarm[0].featureCollection, this.selectedFarm[0])
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>