<template>
  <div :id="key" style="position: relative; height: 100%; width:100%; border-radius: 8px"></div>
</template>

<script>
import L from 'leaflet'
import utils from "@/ultis/genUUID"
import 'leaflet/dist/leaflet.css'
let map
export default {
  name: "MapPreview",
  data () {
    return {
      geojsonLayer: undefined
    }
  },
  props: {
    id: {
      required: true
    },
    geojson: {
      required: true
    }
  },
  watch: {
    geojson(val) {
      if (this.geojsonLayer) map.removeLayer(this.geojsonLayer)
      this.geojsonLayer = L.geoJson(this.geojson, {
        style: {
          'color': 'yellow',
          'weight': 2,
          'opacity': 1
        }
      }).addTo(map)
      map.fitBounds(this.geojsonLayer.getBounds(20, 20))
    }
  },
  mounted () {
    map = L.map(this.key, {zoomControl: false, scrollWheelZoom: false, attributionControl: false}).setView([51.505, -0.09], 5)
    L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      maxZoom: 17,
      tileSize: 256,
      minZoom: 0
    }).addTo(map)
    map.dragging.disable()
    this.geojsonLayer = L.geoJson(this.geojson, {
      style: {
        'color': 'yellow',
        'weight': 2,
        'opacity': 1
      }
    }).addTo(map)
    map.fitBounds(this.geojsonLayer.getBounds(20, 20))
  },
  computed: {
    key () {
      return utils.getUUID() + this.id
    }
  }
}
</script>

<style scoped>

</style>
