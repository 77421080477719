export class Area {
  transformUnit(area) {
    return Math.round( area / 10000 * 100) / 100 + ' ha'
    // if (area > 1000000) return Math.round( area / 1000000 * 100) / 100 + ' km<sup>2</sup>'
    // else if (area > 10000) return Math.round( area / 10000 * 100) / 100 + ' ha'
    // else return Math.round( area * 100) / 100  + ' m<sup>2</sup>'
  }
}

const AreaTransform = new Area()

export default AreaTransform
