<template>
  <v-select
      background-color="#424242"
      v-model="year"
      :items="years"
      :hide-details="$attrs['hide-details']"
      :solo="$attrs['solo']"
      :solo-inverted="$attrs['solo-inverted']"
      :outlined="$attrs['outlined']"
      :dense="$attrs['dense']"
      :chips="$attrs['chips']"
      :small-chips="$attrs['small-chips']"
      :label="$attrs['label']"
      :placeholder="$attrs['placeholder']"
      :multiple="$attrs['multiple']"
      :rules="rules"
      item-color="secondary"
  >
    <template v-slot:item="{ item }">
      <v-layout align-center justify-center>
        {{item}}
      </v-layout>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "YearPicker",
  data() {
    return{
      years: []
    }
  },
  props: {
    value: {type: Number, default: undefined},
    rules: {type: Array, default: () => []}
  },
  computed: {
    year: {
      get () {
        return this.value
      },
      set (val) {
        this.value = val
        this.$emit('input', this.value)
      }
    }
  },
  created() {
    let currentYear = new Date().getFullYear()
    this.years = [...Array(currentYear - 2016 + 1).keys()].map(x => x + 2016).reverse()
  }
}
</script>

<style scoped>

</style>