<template>
  <v-layout style="max-height: 150px;" justify-center column>
    <v-autocomplete
        outlined
        dense
        background-color="primary"
        v-model="location"
        :items="results"
        @change="(val) => $emit('onSearch', val)"
        @keyup.enter="zoomTo"
        :loading="isLoading"
        :search-input.sync="search"
        @input="debounceInput"
        color="white"
        clearable
        hide-no-data
        hide-selected
        item-text="full_name"
        return-object
        item-value="code"
        label="Search location"
        append-icon="mdi-magnify"
        prepend-inner-icon="mdi-map"
        :hide-spin-buttons="true"
    ></v-autocomplete>
  </v-layout>
</template>

<script>
import {debounce} from "lodash"
import {searchLocation} from "@/backend";

export default {
  name: "SearchLocation",
  data() {
    return {
      address_name: undefined,
      isLoading: false,
      search: '',
      location: undefined,
      results: []
    }
  },
  watch: {
    search(val) {
      if (val) this.debounceInput()
    }
  },
  methods: {
    zoomTo() {
      if (/^([-+]?)([\d]{1,3})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,2})((\.)(\d+))?)$/.test(this.search)) {
        this.$emit('zoomToCenter', this.search)
      }
    },
    debounceInput: debounce(function (e) {
      this.searchLocation()
    }, 500),
    async searchLocation() {
      if (!this.search) {
        this.results = []
        return
      }
      try {
        let res = await searchLocation(this.search)
        this.results = res.data.data
      } catch (e) {
        console.log(e)
      } finally {

      }
    }
  }
}
</script>

<style scoped>
/deep/
.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}
</style>
