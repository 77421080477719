<template>
  <v-menu
      ref="menu"
      v-model="dateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :color="color"
          :background-color="$attrs['background-color'] ? $attrs['background-color'] : 'primary'"
          :clearable="$attrs['clearable']"
          hide-details
          :outlined="$attrs['outlined']"
          :solo-inverted="$attrs['solo-inverted']"
          v-model="date"
          :dense="$attrs['dense']"
          prepend-inner-icon="mdi-calendar-range"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="rules"
          :disabled="disabled"
      >
        <template v-slot:prepend-inner>
          <v-layout class="fill-height" align-center>
            <v-icon>mdi-calendar-range</v-icon> <span style="font-size: 12px" class="mx-2" v-if="label">{{label}}:</span>
          </v-layout>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        :min="min"
        :max="max"
        @change="dateMenu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    color: {type: String, default: undefined},
    value: {type: String, default: undefined},
    min: {type: String, default: undefined},
    max: {type: String, default: undefined},
    label: {type: String, default: undefined},
    rules: {type: Array, default: undefined},
    disabled: {type: Boolean, default: false}
  },
  watch: {
    value(val) {
      this.dateModel = this.value
    }
  },
  computed: {
    date: {
      get() {
        return this.dateModel
      },
      set(val) {
        this.dateModel = val
        this.$emit('input', this.value)
      }
    }
  },
  data: () => ({
    dateMenu: false,
    dateModel: undefined
  }),

  created() {
    this.dateModel = this.value
  }
}
</script>
