<template>
  <v-dialog
      width="500"
      v-model="dialog"
  >
    <v-card>
      <v-card-text class="py-2">
        <v-layout column>
          <div v-for="(item, key) in properties">{{key}}: {{item}}</div>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PopupProperties",
  data() {
    return {
      dialog: false,
      properties: {}
    }
  },
  methods: {
    openDialog(properties) {
      this.properties = properties
      this.dialog = true
    }
  }
}
</script>

<style scoped>

</style>
